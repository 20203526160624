@charset "UTF-8";


$black: #333;
$color_theme: #a77e52;
$marron: #a77e52;

$jaune:  #E3B23C;
/*
normalise
*/
@import "vendors/animate";
@import "componement/base";
@import "componement/mixins";

@import "componement/over_materialize";
@import "componement/menu";
@import "componement/menu_icon";
@import "vendors/grid";
//@import "vendors/slick-theme";
//@import "vendors/slick";
@import "componement/icons";


@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Vidaloka');


$typo1 : 'Vidaloka', serif;
$typo2 : 'Open Sans', sans-serif;
/*
font-family: 'Roboto', sans-serif;
font-family: 'bw_gradualregular';
*/
/*
###########################
GO
########################### 
*/


.luminosity:before{    
	mix-blend-mode: hard-light;
}
      
body,html{
	    min-height: 100vh;
		color: #020633;
		font-family: $typo2;
		font-weight: 400 !important;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-ms-font-smoothing: antialiased;
		-o-font-smoothing: antialiased;
		letter-spacing: 0px;
}
body main{
	
	@include transition(all .3s ease);
	
}
html,body {

		
		
	font-size: 15px;
	line-height: 30px;
}

b,strong,small {
	/*font-family:$typo1;*/
}


figure{
	outline: 0;
	line-height: 0;
	margin: 0;
	line-height: 0;
}	
section {

	width: 100%;
	overflow: hidden;
}
.hero {
	height: 100vh;
}

.hero .valign-wrapper,
.hero .container {
	height: 100%;
}
.hero2 {
	height: 450px;

}

.bgblu:before {
	background-color: rgba(#000,.3);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: block;
}
h1,h2,h3,h4,h5,h6,#tooltip-bottom {
	font-family: $typo2;
	    /*font-weight: 400 !important;*/
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-font-smoothing: antialiased;
	    -ms-font-smoothing: antialiased;
	    -o-font-smoothing: antialiased;
	    letter-spacing: 0px;
		    margin-top: 5px;
			    font-weight: 300;
			
		
}
.right li {
	display: inline-block;
	margin-right: 10px;
}
.news {
	margin-bottom: 20px;
	display: block;
	position: relative;
	color: #000;
	margin-top: 20px;
}
img {
	max-width: 100%;
}
.logo {
	display: inline-block;
	padding-top: 40px;
	    padding-bottom: 40px;
	    font-family: Roboto,sans-serif;
}
.right {
	padding-top: 25px;
}
.right li a {
	color: #666;
}

section {
	background-color: #EEE;
}
section.nav {
	background-color: #FFF;
}
.padd {
	padding-bottom: 40px;
	padding-top: 40px;
}
.txt {
	font-weight: 300;
	color: #777777;
	line-height: 32px;
	font-size: 24px;
}

h2 {
	font-weight: 600;
	color: #000;
	line-height: 38px;
	font-size: 28px;
}
h3 {
	font-weight: 600;
	color: #000;
	line-height: 34px;
	font-size: 24px;
}
.row.masonry {
	
	margin-left: -30px;
	padding-left: 0px;
}
.row.masonry .col.item {
	padding: 0;
	padding-left: 30px;
	margin-bottom: 30px;
	margin-top: 0;
}
.row.masonry .col.item .inner {
	background-color: #FFF;
	padding: 30px;
}
.container{
	width: 90%;
	max-width: 1600px;
}


footer{
background-color:#171717;
text-align: center;
color:#FFF;
}
.linkfooter{
	text-align: center;
}
.linkfooter li a{
	color:#595959;
	text-transform:uppercase;
	    margin-left: 5px;
    margin-right: 5px;
        overflow: hidden;
            display: block;
}
.linkfooter li{
	    display: inline-block;
    text-align: center;
}
footer .container{
	    padding-top: 100px;
    padding-bottom: 100px;
}
footer a{
	color:#FFF;
}
footer a,
header a{
	position:relative;
	@include transition(color .3s ease);
}
footer a:before,
header .left a:before{
	    content: " ";
    position: absolute;
    bottom: 24px;
    left: 0;
    background-color: rgba($color_theme,0.3);
    height: 8px;
    width: 100%;
    top: 15px;
    display: block;
    @include transition(all .3s ease);
    transform: translateX(-100%);
}
footer a:hover:before,
header .left a:hover:before{
	transform: translateX(0%);
}
footer a,
footer span.cr,
header .left a{
	display:inline-block;
	overflow: hidden;
}
footer a:hover{
	color:#FFF;
}

.item{
	height:auto;

}
.item iframe{
	    max-width: 100%;
}

.item small{
	font-size: 12px;
	line-height: 14px
}
.item h3{
	    padding-top: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
}
.item img{
	width: 100%;
}

.paddtop {
    padding-top: 113px;
}
section{
	background-color:transparent;
}
.nav{

}
.nav-down{
	@include transition(all .3s ease);
	    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

/* client */
.black {
		color: #FFF;
		background-color: #000;
	}
	.black * {
		color: #FFF;
	}
	section, footer {
		min-height: 300px;
		padding: 0;
		margin: 0;
	}
	
	.logo {
		    width: 100px;
			position: absolute;
			top: 0px!important;
			left: 0px!important;
			z-index: 1000;
			padding: 0;
	}
	
	.logo path {
		fill:  #000;
	}
	
	.logo.wht path{
		fill: #000;
	}
	.logo.blck path{
		fill: #000;
	}
	#exemple {
		width: 100px;
		height: 100px;
		display: block;
		background-color: red;
		position: fixed;
		top: 200px;
		left: 100px;
		z-index: 20000;
	}
	.cursor {
		    background-color: #ffef73;
		    border-radius: 50%;
		    display: block;
		    position: fixed;
		    margin-top: -10px;
		    margin-left: -10px;
		    width: 20px;
		    height: 20px;
		    pointer-events: none;
		    mix-blend-mode: multiply;
		    transform: scale(1);
		    transition: transform .35s ease-out;
		    z-index: 1000;
		
	}
	.top {
		height: 100vh;
	}
	.row {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	#navigation,#navigation ul {
		text-align: right;
		display: inline-block;
	}
	.logo {
	    
	    top: 45px!important;
	    left: 4vw!important;
	    
	}
	
	.grayscale {
	         filter: grayscale(100%);
	             -webkit-filter: grayscale(100%);
	             -moz-filter: grayscale(100%);
	             -ms-filter: grayscale(100%);
	             -o-filter: grayscale(100%);
	     }
.colorize {
	position: relative;
}
 .colorize .cover {
 	width: 100%;
 	height: 100%;
 	display: block;
 	position: absolute;
 	top: 0;
 	left: 0;
 	opacity: .2;
 	
 	filter: grayscale(100%);
 	    -webkit-filter: grayscale(100%);
 	    -moz-filter: grayscale(100%);
 	    -ms-filter: grayscale(100%);
 	    -o-filter: grayscale(100%);
 	
 }
 .colorize .over {
 	height: 100%;
 	width: 100%;
 }
  .colorize .over {
  	padding: 4vw;
  	font-family: 'Roboto', sans-serif;
  	position: relative;
  }
  
  #tooltip-bottom, h1, h2, h3, h4, h5, h6 {
      margin-top: 5px;
      font-weight: 700;
      letter-spacing: 0px;
  }
  body, p {
  	    font-size: 1.2rem;
  		letter-spacing: 0;
  }
  nav ul a {
      font-size: 1.2rem;
      letter-spacing: 0;
  }
  .btnn {
  	background-color: #FFF;
  	color: #000;
  	padding: 15px;
  	border-radius: 30px;
  	padding-left: 30px;
  	padding-right: 30px;
  	margin-top: 20px;
  	display: inline-block;
  }
  
  .row .col.s4 {
  	padding-top: 30px;
  	padding-bottom: 30px;
  }
  
  footer .partenaires li {
  	display: inline-block;
  	height: 47px;
  	opacity: .6;
  /*	filter: grayscale(100%);
  	-webkit-filter: grayscale(100%);
  	-moz-filter: grayscale(100%);
  	-ms-filter: grayscale(100%);
  	-o-filter: grayscale(100%);*/
  	
  	margin: 5px;
  	margin-left: 10px;
  	margin-right: 10px;
  	transition: .3s ease-out;
  }
  footer  li {
  	display: inline-block;
  	margin: 5px;
  	margin-left: 10px;
  	margin-right: 10px;
  }
  .listfooter *{
  	color: #000;
  }
  footer li img {
  	max-width: none;
  	max-height: 100%;
  	 
  }
  
  footer li:hover {
  	   opacity: 1;
  		 filter:none;
  }
  
  .liner:after {
      content: "";
      display: block;
      width: 100%;
      left: -100%;
      height: 10px;
      position: absolute;
      z-index: 0;
      bottom: 4px;
      background: #ffef73;
      opacity: .5;
      transform: translateX(0);
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -ms-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease;
  }
  .liner:hover::after {
  	   transform: translateX(100%);
  	}
  	.listfooter a:hover {
  		color: #000;
  	}
  	
  	footer a:before {
  		content: none;
  	}
  nav li, nav li a:hover {
  	background-color: transparent;
  }
  nav li, nav li a {
  	position: relative;
  	overflow: hidden;
  }
   nav li a.liner:after {
  	bottom: 20px;
  }
  nav ul a {
      font-size: 1rem;
      display: block;
      margin: 0 15px;
	       font-weight: 700;
	   padding: 0;
  }
  
  footer ul {
  	text-align: right;
  	
  }
  footer ul.partenaires {
  	text-align: left;
  }
  footer .container {
  	padding-top: 50px;
  	padding-bottom: 50px;
  }
  footer {
  	min-height: 0;
  }
  .logo {
      top: 25px;
      left: 0!important;
  }
  .topsection .s6 {
  	min-height: 450px;
  }
  .fullnav {
  	position: fixed;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100vh;
  	background-color: #110f10;
  	z-index: 100;
  }
.menuicon span {
	background-color: #FFF;
}

.fullnav {
	transform: translateY(100%);
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	-ms-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
	
	transition-delay: .4s;
	
}
.fullnav:before {
	display: block;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	
	transform: translateY(100%);
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	-ms-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
	
	transition-delay: .2s;
	
	
}
.open .fullnav:before{
	transform: translateY(0%);
	transition-delay: .8s;
}
.open .fullnav {
	 transform: translateY(0%);
	
	 /*padding-top: 100px;*/
}
.button-collapse.open {

    z-index: 100000;
	
}
.menumobile,
ul.menumobile,
#navigation ul.menumobile {
	text-align: left!important;
	display: block;
}
.menumobile li a {
	    font-size: 4rem;
		line-height: 5rem;
}
.menumobile li,
#navigation ul.menumobile li {
	display: block;
	float: none;
}
.menumobile li {
	overflow: hidden;
	    height: 70px;
}
#navigation ul.menumobile li a {
	display: inline-block;
	margin: 0;
}

#navigation.open {
	position: fixed;
}
nav .button-collapse {
    margin-top: 21px;
	margin-right: 0;
}

#navigation, #navigation ul.menudesktop {
	right: -14px;
	margin: 0;
	padding: 0;
	position: absolute;
}

#navigation ul.leftul {
	display: block;
	width: 100vh;
	transform: rotate(-90deg);
	position: absolute;
	left: 0;
	transform-origin: top left;
	top: 100vh;
	left: 0;
	display: block;
	width: 100vh;
	text-align: center;
	
}
#navigation ul.leftul li {
	display: inline-block;
	text-align: center;
	float: none;
}
nav {
	top: 28px;
}
nav i, nav i.material-icons {
    display: inherit; 
    font-size: inherit; 
    height: auto; 
    line-height: inherit;
}

.trait {
	padding-left: 40px;
	position: relative;
	
}
.trait:before {
	content: "";
	height: 1px;
	width: 30px;
	position: absolute;
	top: 2px;
	left: 0;
	    background-color: #000;
}
.menudesktop .trait:before {
	top: 31px;
}

/* present /page */

.topsection2:before{
		content: "";
	    position: absolute;
	    background: linear-gradient(transparent,rgba(0,0,0,.4));
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    display: block;
		mix-blend-mode: hard-light;

}
.topsection2 {
	    height: 450px;
}
.topsection2 h1 {
	position: absolute;
	bottom: 40px;
	left: 0;
	display: block;
	height: auto;
}
.topsection2  .container {
	    height: 100%;
}
.containermin {
	max-width: 850px;
	display: block;
	margin: 0 auto;
	padding-top: 50px;
	margin-bottom: 50px;
}
.topsection2 * {
	color: #FFF;
}

.collapsible-header,
.collapsible {
	border: none;
	box-shadow:none;
	
}
.collapsible-header {
	font-size: 30px;
	font-weight: 500;
	position: relative;
	padding-left: 47px;
}
.collapsible-header i {
	position: absolute;
	top: 17px;
	left: 0;
	font-size: 35px;
	    font-weight: 500;
}
.collapsible-body {
	padding: 0;
	padding-bottom: 40px;
	padding-top: 10px;
}

.menumobile li a {
    font-size: 2rem;
    line-height: 3rem;
}

.menumobile .trait:before {
    height: 1px;
    width: 30px;
    top: 20px;
    background-color: #FFF;
}
.marron {
	background-color: $marron;
}
.backmenu {
	background-color: #FFF;
	width: 100%;
	height: 120px;
	display: block;
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
}
nav ul a {
	color: #000;
}
.logo {
	top: 33px;
}
#navigation, #navigation ul.menudesktop {
	top: 0;
}
#navigation ul.menudesktop {
	top: 14px;
}
.top {
    height: 600px;
}
.btnn {
	    padding: 10px 24px;
}

#navigation ul.menudesktop {
	top: 25px;
}

h1,h2,h3,h4 {
	font-family: $typo1;
	font-weight: 400!important;
}
.logo {
    top: 34px!important;
}
.colorize .cover {
	
}
.menuicon span {
    background-color: #000;
}
nav .button-collapse {
    margin-top: 42px;
    margin-right: 0px;
}
#navigation ul.menumobile li a {
   color: #FFF;
}
.menuicon.open span {
	background-color: #FFF;
}

#navigation ul.menumobile li a.liner:after {
    bottom: 8px;
}
#navigation.open .logo path {
	fill: #FFF;
}
#fullnav {
	display: none;
}
h2 {
	color: #333;
}
h3 {
	color: #555;
	font-family: $typo2;
	font-weight: 800!important;
}
.txxt {
	font-size: 18px;
}
.txxt b,
.txxt strong {
	
	    font-weight: 700!important;
	
}
.jaune {
	background-color: $jaune;
}
//@import "componement/galery";
//@import "componement/responsive";
.colorize.marron:before {
	    background: linear-gradient(transparent,rgba(167,126,82,.4)); 
}
.colorize.jaune:before {
	 background: linear-gradient(transparent,rgba(227,178,60,.4)); 
}
.logo {
    top: 37px!important;
	width: 132px;
}
.topsection>.row {
	margin-top: 120px;
}
.top {
	height: 450px;
}
@media screen and(max-width: 450px) {

	.news .col{
		width: 100%!important;
		float: none;
		padding: 0;
		margin: 0;
		display: block;
	}
	
	
}
@media screen and(max-width: 600px) {
	.row .col.s6 {
	    width: 100%;
	}
	footer ul.partenaires,
	.listfooter {
	    text-align: center;
	}
	#fullnav {
		display: block;
	}
	
}