@charset "UTF-8";


$color_icon_menu:#41484A;
$color_icon_menu_open : #41484A;



.menuhamburger {
  width: 30px;
      height: 30px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.menuhamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $color_icon_menu;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


.menuhamburger span:nth-child(1) {
  top: 0px;
}

.menuhamburger span:nth-child(2) {
  top: 9px;
}

.menuhamburger span:nth-child(3) {
  top: 18px;
}

.menuhamburger.open span:nth-child(1) {
      top: 9px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.menuhamburger.open span:nth-child(2) {
  opacity: 0;
  left: -30px;
}

.menuhamburger.open span:nth-child(3) {
      top: 9px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}


.menuhamburger{
	float: left;
	margin-left: 20px;
	margin-top: 20px;
	z-index: 10000;
	
	    top: 18px;
	    left: 20px;
}

.menuhamburger {
    width: 30px;
    height: 30px;
    position: absolute;
    margin: 0 auto;

    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}


.menuhamburger span{
	background-color: $color_icon_menu;
	@include transition(all .3s ease);
}

.menuhamburger.open span,
.blackk .menuhamburger span{
	background-color: $color_icon_menu_open;
	@include transition(all .3s ease);
}

nav .button-collapse {
    float: right;
    z-index: 1;
	margin-right: 40px;
}

nav {
    background-color: transparent;
	box-shadow:none;
}
.logoleft a {
	margin-top: 20px;
	display: block;
	margin-bottom: 20px;
}
.logoleft a img {
	margin: 0 auto;
	width: 200px;
	    display: block;
}

.side-nav li {
    float: none;
    line-height: 48px;

}
nav ul a {
	color: #fff;
	    font-size: 14px;
}
nav {
	background-color: transparent;
	    box-shadow: none;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    /* height: 100px; */
	    z-index: 10;
	    padding-top: 20px;
	    padding-bottom: 20px;
	    height: 100px;
}






/* Overlay style */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0,.3);
	opacity: 0;
	pointer-events: none;
}
.overlay.open{
	opacity: 1;
	pointer-events: initial;
	cursor: pointer;
	z-index: 998;
}
.overlay {
	height: 100vh;
	overflow: scroll;
	margin-bottom: 30px;
}
.overlay ul.inner{
	display: block;
	height: auto;
}

.overlay ul.inner li,
.overlay ul.inner li a,
.overlay ul.inner li span{
	list-style: none;
	width: 100%;
	display: block;
	color: #000;
	text-align: center;
	
	
}
.overlay ul.inner li{
	display: block;
	position: relative;
	min-height: 34px;
}
.overlay ul.inner li a{
	line-height: 34px;
}

/* Effects */
.overlay-scale {
	visibility: hidden;
	opacity: 0;
    transform: scale(0.9);
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	    z-index: 998;
		pointer-events: none;
}

.overlay-scale.open {
	visibility: visible;
	opacity: 1;
    transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
	
}



.overlay-scale.open,.overlay-scale{
	background-color: rgba(255, 255, 255, .75);
}


.overlay nav {
	text-align: center;
	    display: -webkit-box;
	        display: -webkit-flex;
	        display: -ms-flexbox;
	        display: flex;
	        -webkit-box-align: center;
	        -webkit-align-items: center;
	        -ms-flex-align: center;
	        align-items: center;
			height: 100%;
}
.overlay ul li {
	display: block;
	position: relative;
	width: 100%;
	text-align: center;
}
.overlay ul {
	display: block;
	margin: 0 auto;
	
}
.overlay ul li a {
	color: #000;
	font-family: gilroyextrabold;
	font-size: 30px;
}
.overlay ul li:hover,
.overlay ul li a:hover {
	background-color: transparent;
}

.logo {
	top: 2vw;
	left: 2vw;
}

#hmbrgr {
	position: fixed;
	top: 4vw;
	right: 4vw;
	display: block;
}

.overlay .row {
	height: 100%;overflow: hidden;margin: 0;
}
.overlay .col {
	height: 100%;margin: 0;
}
.overlaytxt {
	background-color: #FFF;
}




/* menu2 */
/*.side-nav.full {
	width: 100%;
}
.side-nav.full li {
    border: none;
}*/







