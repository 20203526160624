

/* Icon 1 */

.menuicon {
  width: 30px;
      height: 30px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.menuicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $color_icon_menu;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.menuicon span:nth-child(1) {
  top: 0px;
}

.menuicon span:nth-child(2) {
  top: 9px;
}

.menuicon span:nth-child(3) {
  top: 18px;
}

.menuicon.open span:nth-child(1) {
      top: 9px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.menuicon.open span:nth-child(2) {
  opacity: 0;
  left: -30px;
}

.menuicon.open span:nth-child(3) {
      top: 9px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}


